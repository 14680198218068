
import React, { Component } from "react";
import {Button, Grid} from 'semantic-ui-react';
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import './B11U1L1.scss';

export default class B11U1L1 extends Component {

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

 
    return (

      // <div class="contenedor"> 
 
<Grid style={{ backgroundColor: '#101010'}}>

  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>

  <Grid.Column widescreen={14} largeScreen={14} computer={14}  tablet={14} mobile={14} > 
    <>
    <h2 className="lesson1title"><br></br>Lesson 1</h2>
    <Slider {...settings}>
    <div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F17ff978c-0853-4f5b-84d5-0c98cd066d87?alt=media&token=aa85844a-675c-4538-acfb-33539a280757"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F051f0203-4027-4ddd-a00d-859c3dbe4c15?alt=media&token=4f5f4d1f-d12a-4a84-88c7-5a011987eed1"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2Fc22b4aff-2c82-4917-a2bd-e6b110f1bf1e?alt=media&token=6413fa12-8d16-4dd4-9f90-829a7baa1d8f"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2Fa08af0c0-3495-4cb8-a59f-3c3af2f51d4b?alt=media&token=9fb0d381-70b3-4f33-b9c8-7e9f68af5e5c"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2Fdb0f6df8-543a-4570-946c-66b24bb7720d?alt=media&token=d7a28523-f71e-4367-ae3a-0695990dbfa9"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F32acf9f2-3017-49f6-97b3-4ecfd78791de?alt=media&token=4038f6ff-5ba5-4ab7-81ab-a3e1c1c39cfe"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2Fa5c254f8-6e04-4a90-8f8f-c532306ea7b9?alt=media&token=cfbc4370-5bdf-4eb1-8a27-da2d1aa2a2c9"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F8b0a1a86-1675-4234-8346-cb4026fecb59?alt=media&token=d223f579-02ef-4820-8768-aa209ccd64cf"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2Fca98b3ee-1a43-4c25-9389-ea9b12078b1b?alt=media&token=82260d5c-1b21-4900-af5d-b9819b4a6109"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F6eb32836-097d-4f55-ba5e-f9f87924044a?alt=media&token=b2ef8179-33a0-47a1-88af-24be1890f200"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F96c3c5c3-1488-4a6c-a914-8430f80996e5?alt=media&token=76ff9d8b-97a5-4a7b-bfdf-49884a47c1e9"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F3339a106-40b7-4a34-9791-eafe9ab35bf5?alt=media&token=b12a4c73-5489-42eb-a3f6-10fa7e2bb757"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2Fed10f9c6-ce5b-4cff-80ec-e7159e478efd?alt=media&token=0405c982-a063-4503-b407-247a69e8c980"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F3dc2625c-eb89-4a57-80c6-5dd6226c86b5?alt=media&token=3d6f15da-c2ac-408b-854f-9b27f7c06a0d"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F271bc731-1f65-4186-acc0-6b85762de94d?alt=media&token=9ea9a392-ec6d-40e6-8f0b-68969bcf0756"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F615badb7-0517-47c7-8520-f7d91dd55e5a?alt=media&token=010f47d6-8b25-499f-acf5-d6e85f273dd4"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F8dbe3902-9050-482b-a2d5-b3a5a26367ae?alt=media&token=47d0e823-d8c8-44f5-b66e-0bfadc908397"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F36c762f2-d621-41a8-8c5e-e4da0524489b?alt=media&token=86747fab-5a50-41f7-aa31-5fa673c5889a"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2Fae439ffa-cc28-44cc-837c-df54902aeb73?alt=media&token=07efb8cd-9baa-4745-9d2c-6e4f5b88347a"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F8643146d-1c42-445f-a293-8f0233fa3676?alt=media&token=cedd0207-243c-4eb3-96e9-b9c146e8f088"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2F06788642-c35c-4e36-bcb8-3a4422e53b5b?alt=media&token=846aa12e-9868-423b-917d-7d0c7567e21c"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
<div>
	<img src={"https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2Fe1a21344-0d6a-47f2-9dc1-c4a99357f4d6?alt=media&token=711ced05-d908-44e5-9ee3-7a50d87ad69c"} alt="boohoo" className="img-responsive"/>
	<br/>
</div>
          

        </Slider>
      </>
      <div class="center"> 
      <Button href='https://firebasestorage.googleapis.com/v0/b/spanish-diversity-scourse.appspot.com/o/Special%20Course%2FSpanish%2FDIVU10ES%2FDIVU10ESMAT.pdf?alt=media&token=8a0d6a0b-69db-40e6-b744-38e61ead8211' download className="btn-download-uno">Download resources</Button>
      </div>
  </Grid.Column>
  
  <Grid.Column widescreen={1} largeScreen={1} computer={1}  tablet={1} mobile={1} > 
  
  </Grid.Column>
</Grid>
 
      // </div>
    );
  }
}
